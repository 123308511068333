import React, { useState } from 'react';
import YouTube from 'react-youtube';
import * as Icons from 'react-icons/fa';
import Vimeo from '@u-wave/react-vimeo';
import { getLinkTo } from '../../helpers/linkResolver';
import { Link, Section, Image, OnVisible, BlockContent } from '../../components';
import * as styles from './styles.module.scss';

const DynamicFontAwesomeIcon = ({ name }) => Icons[name];

const ContentSide = ({ data }) => {
  const [loadVideo, setLoadVideo] = useState(false);
  const imageInternalLink = data?.imageLink?.internalLink?.slug?.current || null
  const externalImageLink = data?.imageLink?.externalLink || null
  const internalLinkTwo = data?.buttonLinkTwo?.slug?.current || null
  const externalLinkTwo = data?.externalButtonLinkTwo || null
  const fileDownload = data?.buttonLink?.pdfFile?.asset?.url
  const externalLink = fileDownload? fileDownload : data?.externalButtonLink;
  const blockContentData = data?._rawRichText;
  const alignRight = data?.ImageRight ? styles.alignImageRight : '';
  const greyBg = data?.tileBG ? `${styles.greyBg} grey-bg` : 'white-bg';
  const id = data?.video?.split('v=')[1];
  const ampersandPosition = id?.indexOf('&');
  const vidId = ampersandPosition !== -1 ? id?.substring(0, ampersandPosition) : id;
  const imageLink = externalImageLink || imageInternalLink

  const handleLoadVideo = (visible) => {
    if (visible) {
      setLoadVideo(true);
    }
  };

  const isYoutube = !!data?.video?.includes('youtube');
  const isVimeo = !!data?.video?.includes('vimeo');

  return (
    <Section
      className={`content-side-section ${greyBg} ${alignRight} ${styles.section}`}
      containerClassName={styles.container}
    >
      {(data?.title || data?.content) && (
        <div className={styles.titleContainer}>
          {data?.title && <span className={styles.title}>{data.title}</span>}
          {data?.subtitle && <span className={styles.content}>{data.subtitle}</span>}
        </div>
      )}
      <div className={styles.grid}>
        <>
          {/* IF YOUTUBE VIDEO */}
          {data?.video && (
            <OnVisible onChange={handleLoadVideo} className={`youtube-video-embed ${styles.mediaContainer}`}>
              {isYoutube && loadVideo && (
                <YouTube
                  videoId={vidId}
                  opts={{
                    playerVars: {
                      autoplay: false,
                      allowfullscreen: true,
                      rel: 0,
                      modestbranding: 1,
                      loop: 1,
                      showinfo: 0,
                    },
                  }}
                  className={styles.youtubeVideo}
                  containerClassName={styles.youtubeContainer}
                />
              )}
              {/* IF VIMEO VIDEO */}
              {isVimeo && (
                <div className={styles.videoContainer}>
                  <Vimeo video={data.video} id="video" className="vimeo-video" />
                </div>
              )}
            </OnVisible>
          )}
          {/* IF IMAGE AND NO VIDEO */}
          {data?.image && !data?.video && imageLink &&
          <Link to={externalImageLink? getLinkTo(externalImageLink): `/${imageInternalLink}`}>
              <Image image={data.image.asset} className={styles.image} />
          </Link>
          }
          {data?.image && !data?.video && !imageLink &&
              <Image image={data.image.asset} className={styles.image} />
          }
        </>
        <div className={styles.textContainer}>
          <div className={styles.blockContent}>
            <span className={styles.columnTitle}>{data.columnTitle}</span>
            {blockContentData && <BlockContent data={blockContentData} className="block-content" />}
          </div>
          {data?.iconTextSubtitle?.length > 0 && (
            <div className={styles.features} visibleClassName={styles.visibleGrid}>
              {data?.iconTextSubtitle?.map((item, index) => {
                const { icon, title, subtitle } = item;
                const Icon = DynamicFontAwesomeIcon(icon);
                return (
                  <div className={styles.card} style={{ transitionDelay: `${index * 0.2}s` }} key={title}>
                    <Icon className={styles.icon} />
                    <div>
                      <span className={styles.cardTitle}>{title}</span>
                      <span className={styles.cardContent}>{subtitle}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {(data.buttonText || externalLink) && (
            <Link to={getLinkTo(externalLink || data?.buttonLink)} className={`button secondary ${styles.cta}`}>
              {data.buttonText}
            </Link>
          )}
          {(internalLinkTwo || externalLinkTwo) && (
            <Link to={getLinkTo(externalLinkTwo || `/${internalLinkTwo}`)} className={`button ${styles.ctaTwo}`}>
              {data.buttonTextTwo}
            </Link>
          )}
        </div>
      </div>
    </Section>
  );
};

export default ContentSide;

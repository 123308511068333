import React, { useState, useEffect } from 'react';
import Chevron from '../../images/icons/brown-chevron.inline.svg';
import { Section, Image, Link, Modal } from '../../components';
import * as styles from './styles.module.scss';
import { getLinkTo } from '../../helpers/linkResolver';

const Team = ({ data }) => {
  const [showModal, setShowModal] = useState(false);
  const [showModalIndex, setShowModalIndex] = useState(null);

  const openModal = (event, index) => {
    event.preventDefault();
    setShowModalIndex(index);
    setShowModal(true);
  };

  const closeModal = (event) => {
    event.preventDefault();
    setShowModalIndex(null);
    setShowModal(false);
  };

  return (
    <Section
      className={`${data?.tileBG ? styles.greyBg : ''} ${styles.section}`}
      containerClassName={styles.container}
      sliceName="TeamMembers"
    >
      <div className={styles.textContainer}>
        {data?.title && <span className={styles.title}>{data?.title}</span>}
        {data?.subtitle && <span className={styles.content}>{data?.subtitle}</span>}
      </div>
      <div className={styles.grid}>
        {data?.person.map((item, index) => {
          const { person } = item;

          return (
            <div className={styles.card} key={person?.name}>
              <Image image={person?.image?.asset} className={styles.image} />
              <span className={styles.role}>{person?.title}</span>
              <span className={styles.name}>{person?.name}</span>
              <div className={styles.cta} onClick={(e) => openModal(e, index)}>
                <span>View Profile</span>
                <Chevron className={styles.chevron} />
              </div>
              {showModalIndex === index && (
                <Modal active={showModal} closeModal={closeModal} className={styles.modal}>
                  <div className={styles.modalText}>
                    <span className={styles.name}>{person?.name}</span>
                    <span className={styles.role}>{person?.title}</span>
                    <span className={styles.bio}>{person?.bio}</span>
                  </div>
                </Modal>
              )}
            </div>
          );
        })}
      </div>
      {data?.viewAllButtonText && (
        <Link className={`outline button ${styles.mainCta}`} to={getLinkTo(data?.viewAllRoute || '#')}>
          {data?.viewAllButtonText}
        </Link>
      )}
    </Section>
  );
};

export default Team;

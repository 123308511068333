import React from 'react';
import * as Icons from 'react-icons/fa';
import { getLinkTo } from '../../helpers/linkResolver';
import { Section, Link } from '../../components';
import * as styles from './styles.module.scss';

const DynamicFontAwesomeIcon = ({ name }) => Icons[name];

const ThreeColGrid = ({ data }) => (
  <Section className={styles.section} containerClassName={styles.container}>
    {data.title && <span className={styles.title}>{data.title}</span>}
    {data.subtitle && <span className={styles.content}>{data.subtitle}</span>}
    <div className={styles.grid}>
      {data.services.map((item) => {
        const { icon, title, link } = item;
        const Icon = DynamicFontAwesomeIcon(icon || '');
        return (
          <Link to={getLinkTo(link)} className={styles.card} key={title}>
            <div className={styles.iconContainer}>
              <Icon className={styles.icon} />
            </div>
            <span className={styles.cardText}>{title}</span>
          </Link>
        );
      })}
    </div>
  </Section>
);

export default ThreeColGrid;

// extracted by mini-css-extract-plugin
export var section = "styles-module--section--xDaSO";
export var container = "styles-module--container--3-DwU";
export var greyBg = "styles-module--greyBg--25-D-";
export var textContainer = "styles-module--textContainer--1DRjS";
export var title = "styles-module--title--169o5";
export var content = "styles-module--content--1tlnx";
export var grid = "styles-module--grid--iHH9L";
export var card = "styles-module--card--sQ5OS";
export var image = "styles-module--image--3-_NH";
export var role = "styles-module--role--3OMTR";
export var name = "styles-module--name--21tvz";
export var cta = "styles-module--cta--pgNTF";
export var chevron = "styles-module--chevron--2uptv";
export var mainCta = "styles-module--mainCta--21fMI";
export var modal = "styles-module--modal--Bmfd3";
export var bio = "styles-module--bio--3jgHu";
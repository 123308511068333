// extracted by mini-css-extract-plugin
export var section = "styles-module--section--PpJp9";
export var container = "styles-module--container--bUH1T";
export var authorContainer = "styles-module--authorContainer--pC8bM";
export var shareContainer = "styles-module--shareContainer--TJ1SL";
export var iconsContainer = "styles-module--iconsContainer--2K51w";
export var authorText = "styles-module--authorText--1uy7H";
export var name = "styles-module--name--16qUN";
export var date = "styles-module--date--RT5vs";
export var image = "styles-module--image--1hkDI";
export var shareText = "styles-module--shareText--3LEm7";
export var iconContainer = "styles-module--iconContainer--2tW5a";
export var icon = "styles-module--icon--6yzmI";
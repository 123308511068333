import React from 'react';
import { Section, BlockContent } from '../../components';
import * as styles from './styles.module.scss';

const ContentCentre = ({ data }) => (
  <Section className={` ${styles.section}`} containerClassName={styles.container}>
    {(data?.title || data?.content) && (
      <div className={styles.titleContainer}>
        {data?.title && <span className={styles.title}>{data.title}</span>}
        {data?.subtitle && <span className={styles.content}>{data.subtitle}</span>}
      </div>
    )}
    {data._rawRichText && <BlockContent data={data._rawRichText} />}
  </Section>
);

export default ContentCentre;

import React from 'react';
import { Footer, Header, SEO, NoJs } from 'components';
import 'typeface-lato';
import '../../../sass/global/styles.scss';
import './styles.scss';

const Layout = (props) => {
  const { children, seo, location, article } = props;
  return (
    <>
      <NoJs />
      <Header />
      <main>
        <SEO {...seo} article={article || ''} location={location} />
        {children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;

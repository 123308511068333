import React, { useState, useEffect, Fragment } from 'react';
import { use100vh } from 'react-div-100vh';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useStaticQuery, graphql } from 'gatsby';
import { Link, Logo, Section, Hamburger } from 'components';
import * as Icons from 'react-icons/fa';
import Chevron from '../../../images/chevron.inline.svg';
import Phone from '../../../images/phone.inline.svg';
import * as styles from './styles.module.scss';

const DynamicFontAwesomeIcon = ({ name }) => Icons[name];

const Header = () => {
  const headerData = useStaticQuery(graphql`
    query headerQuery {
      sanityNavigation {
        topNav {
          ... on SanitySingletonNavItem {
            _key
            _type
            title
            linkTo {
              ... on SanityCategory {
                id
                title
                services {
                  link {
                    ... on SanityPost {
                      id
                      slug {
                        current
                      }
                    }
                    ... on SanityPage {
                      id
                      slug {
                        current
                      }
                    }
                  }
                }
              }
              ... on SanityPage {
                id
                slug {
                  current
                }
              }
            }
          }
          ... on SanityDropdownIconNavItem {
            _key
            _type
            title
            linkTo {
              ... on SanityPage {
                id
                slug {
                  current
                }
              }
              slug {
                current
              }
            }
            dropdownChildren {
              title
              page {
                slug {
                  current
                }
              }
              icon {
                name
                provider
              }
            }
          }
          ... on SanityDropdownNavItem {
            _key
            _type
            title
            linkTo {
              ... on SanityPage {
                id
                slug {
                  current
                }
              }
            }
            dropdownChildren {
              title
              page {
                ... on SanityPage {
                  id
                  slug {
                    current
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const topNav = headerData?.sanityNavigation?.topNav;
  /// H A M B U R G E R   L O G I C /////////////////////
  const [showNav, setShowNav] = useState(false);
  const [bodyLocked, setBodyLock] = useState(false); // lock body while show nav = true
  const [scrollTop, setScrollTop] = useState(0); // set pixels to - from top when shownav = false
  const [showSublinks, setShowSublinks] = useState(false);
  const [shrinkOnScroll, setShrinkOnScroll] = useState(true);
  // toggle main nav links for mobile
  const toggleNav = (event) => {
    event.preventDefault();
    setShowNav(!showNav);
    if (showNav) {
      setShowSublinks(null);
    }
  };
  // C L O S E   O N   E S C   A N D   B O D Y   L O C K
  const handleEscKey = (event) => {
    // get rid of nav on esc keydown
    if (event.keyCode === 27) {
      document.body.classList.remove('nav-open');
      setShowNav(false);
    }
  };

  const lockBody = () => {
    // lock body while show nav true
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    setScrollTop(scrollPosition);
    document.body.style.top = `-${scrollPosition}px`;
    document.body.classList.add('nav-open');
    setBodyLock(true);
  };

  const unlockBody = () => {
    // when show nav false, unlock body
    document.body.classList.remove('nav-open');
    document.body.style.top = '0px';
    window.scrollTo(0, scrollTop);
    setScrollTop(0);
    setBodyLock(false);
  };

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== shrinkOnScroll && currPos.y < -100) setShrinkOnScroll(isShow);
    },
    [shrinkOnScroll]
  );

  // use effect exit menu on sec keydown
  useEffect(() => {
    document.addEventListener('keydown', handleEscKey);
    return () => {
      unlockBody();
      document.removeEventListener('keydown', handleEscKey);
    };
  }, []);

  // use effect when shownav updated
  useEffect(() => {
    if (showNav && !bodyLocked) lockBody();
    if (!showNav && bodyLocked) unlockBody();
  }, [showNav]);

  // toggle visibility for mobile
  const hamburgerOpenClass = showNav ? styles.hamburgerOpen : '';

  const height = use100vh();

  const handleClick = (event, index) => {
    event.preventDefault();
    if (showNav) {
      if (showSublinks === index) {
        setShowSublinks(null);
      } else {
        setShowSublinks(index);
      }
    }
  };

  return (
    <header
      className={`${styles.header} ${shrinkOnScroll ? styles.showHeader : styles.shrinkHeader} ${
        hamburgerOpenClass || ''
      }`}
    >
      <Section className={styles.section} containerClassName={styles.container}>
        {/* LOGO */}
        <Logo mobileVersion className={styles.logo} />
        {/* // LINKS */}
        <nav className={styles.nav} style={showNav ? { height } : {}}>
          {topNav.map((link, index) => {
            const { title, linkTo, dropdownChildren, _type } = link;
            const topLink = linkTo?.slug?.current;
            const hasIcons = _type === 'dropdownIconNavItem' ? styles.iconSublinks : '';
            return (
              <div className={styles.navItem} key={title} onClick={(event) => handleClick(event, index)}>
                <div className={`${showSublinks === index ? styles.activeParent : ''} ${styles.parentContainer}`}>
                  <Link key={title} className={styles.parentText} to={`/${topLink}`}>
                    {title}
                  </Link>
                  {dropdownChildren && <Chevron className={styles.chevron} />}
                </div>
                {dropdownChildren && (
                  <div
                    className={`${showSublinks === index ? styles.mobileSublinks : ''} ${hasIcons} ${styles.sublinks}`}
                  >
                    {dropdownChildren.map((sublink) => {
                      const { title: sublinkTitle, page } = sublink;
                      const hasIcon = sublink?.icon;
                      const subLinkUrl = page?.slug?.current;
                      const Icon = DynamicFontAwesomeIcon(hasIcon || '');

                      return (
                        <Fragment key={sublinkTitle}>
                          <Link className={styles.sublink} to={`/${subLinkUrl}`}>
                            {hasIcon && (
                              <div className={styles.iconContainer}>
                                <Icon className={styles.icon} />
                              </div>
                            )}
                            {sublinkTitle}
                          </Link>
                        </Fragment>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </nav>
        {/* NAV BAR ACTIONS */}
        <div className={styles.navActions}>
          {/* CTA buttons */}
          <div className={styles.buttonContainer}>
            <Link to="tel:1300000657" className={`button outline black ${styles.button} ${styles.callButton}`}>
              <span>1300 000 657</span>
              <Phone className={styles.phoneIcon} />
            </Link>
            <Link to="/contact-us" className={`button secondary ${styles.button}`}>
              <span>Enquire Now</span>
            </Link>
          </div>
          {/* HAMBURGER */}
          <Hamburger onClick={toggleNav} active={showNav} />
        </div>
      </Section>
    </header>
  );
};

export default Header;

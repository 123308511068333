import React from 'react';
import { Image } from 'components';
import './styles.scss';

const isDev = process.env.NODE_ENV === 'development';

const Section = (props) => {
  const { className, containerClassName, children, sliceName, noContainer, bgImage, bgRight } = props;

  return (
    <section
      className={`section ${className || ''} ${bgRight ? 'bg-image-right' : ''}`}
      data-slice={isDev ? sliceName : null}
    >
      {bgImage && <Image image={bgImage} className="section-bg" />}
      {noContainer ? children : <div className={`container ${containerClassName || ''}`}>{children}</div>}
    </section>
  );
};

export default Section;

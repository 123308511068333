// extracted by mini-css-extract-plugin
export var section = "styles-module--section--3t8rC";
export var container = "styles-module--container--1tY6o";
export var textContainer = "styles-module--textContainer--3y7lT";
export var title = "styles-module--title--2kKb6";
export var content = "styles-module--content--18Lpy";
export var faqContainer = "styles-module--faqContainer--18xgz";
export var questionList = "styles-module--questionList--7pCHx";
export var faqItem = "styles-module--faqItem--3TPZs";
export var mobileAnswer = "styles-module--mobileAnswer--1_Bw9";
export var activeItem = "styles-module--activeItem--3rcNO";
export var chevron = "styles-module--chevron--32kfY";
export var questionText = "styles-module--questionText--2pTIB";
export var answerBox = "styles-module--answerBox--3HdeS";
export var answer = "styles-module--answer--18eXe";
export var currentQuestion = "styles-module--currentQuestion--2NydK";
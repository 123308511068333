import React from 'react';
import { Image, Section, Link } from 'components';
import * as styles from './styles.module.scss';

const PostsGrid = ({ posts }) => (
  <div className={styles.grid}>
    {posts.map((item) => (
      <Card post={item} />
    ))}
  </div>
);

const Card = ({ post }) => {
  const {
    title: cardTitle,
    subtitle: cardContent,
    mainImage,
    postAuthorRef,
    slug,
    body,
  } = post;

  const { authorName } = postAuthorRef || {};

  // extract post body
  const bodyText = [];
  body.forEach((section, i) => {
    const { children } = section;
    bodyText.push(children?.[0]?.text);
  });
  const fullBody = bodyText.join('');
  // mins to read
  const wordsPerMinute = 200;
  const textLength = fullBody?.split(' ').length;
  const minsToRead = Math.ceil(textLength / wordsPerMinute);

  return (
    <div className={styles.card} key={cardTitle}>
      <Image className={styles.image} image={mainImage?.image?.asset} />
      <div className={styles.cardText}>
        <span className={styles.cardTitle}>{cardTitle}</span>
        <p className={styles.cardContent}>{cardContent}</p>
        <div className={styles.authorContainer}>
          <div className={styles.authorText}>
            {authorName && (
              <span className={styles.author}>
                By {authorName} | {minsToRead} min read
              </span>
            )}
          </div>
        </div>
      </div>
      <Link className={styles.cta} to={`/news/${slug?.current}`}>
        Read Article
      </Link>
    </div>
  );
};

export default PostsGrid;

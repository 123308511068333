// extracted by mini-css-extract-plugin
export var section = "styles-module--section--37ngZ";
export var greyBackground = "styles-module--greyBackground--20fOE";
export var carouselContainer = "styles-module--carouselContainer--2c4js";
export var container = "styles-module--container--2IJ1m";
export var grid = "styles-module--grid--29feI";
export var titleContainer = "styles-module--titleContainer--21qFu";
export var title = "styles-module--title--cNzsI";
export var content = "styles-module--content--3gHyK";
export var cta = "styles-module--cta--2zqjL";
export var buttonLeft = "styles-module--buttonLeft--2F7dL";
export var buttonRight = "styles-module--buttonRight--1dSIJ";
export var card = "styles-module--card--3nQj_";
export var imageContainer = "styles-module--imageContainer--1MyG-";
export var image = "styles-module--image--3O8Tt";
export var quote = "styles-module--quote--2gOcp";
export var name = "styles-module--name--1Ymug";
export var role = "styles-module--role--2AGzW";
export var starRating = "styles-module--starRating--nthcD";
export var starContainer = "styles-module--starContainer--2ArXc";
export var stars = "styles-module--stars--1tPvL";
// extracted by mini-css-extract-plugin
export var section = "styles-module--section--2dmqr";
export var tallVersion = "styles-module--tallVersion--2HBw0";
export var textContainer = "styles-module--textContainer--1q5rw";
export var hasServices = "styles-module--hasServices--2XrDg";
export var extraSpace = "styles-module--extraSpace--16kzf";
export var title = "styles-module--title--3s59M";
export var content = "styles-module--content--1l4oL";
export var cta = "styles-module--cta--3ViPL";
export var homepageCta = "styles-module--homepageCta--GLqux";
export var services = "styles-module--services--2bOyB";
export var visibleServices = "styles-module--visibleServices--3eS3O";
export var serviceCard = "styles-module--serviceCard--pQQI5";
export var icon = "styles-module--icon--36KjG";
export var active = "styles-module--active--1eu4M";
export var subserviceContainer = "styles-module--subserviceContainer--lmRDA";
export var cardTitle = "styles-module--cardTitle--2OrqU";
export var cardSubtitle = "styles-module--cardSubtitle--3XO4i";
export var subServices = "styles-module--subServices--3y5dn";
export var card = "styles-module--card--lIw6X";
export var iconContainer = "styles-module--iconContainer--2H8QK";
export var serviceIcon = "styles-module--serviceIcon--mG3YF";
export var cardText = "styles-module--cardText--2V_PU";
import React, { useState, useEffect, useRef } from 'react';
import { Section, Image, Carousel, Link } from '../../components';
import Stars from '../../images/placeholders/icons/5-stars.inline.svg';
import { getLinkTo } from '../../helpers/linkResolver';
import * as styles from './styles.module.scss';

const Testimonials = ({ data }) => {
  const [slickRef, setSlickRef] = useState(0);
  const slickContainerRef = useRef();

  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 7000,
    infinite: true,
    cssEase: 'linear',
    arrows: false,
    speed: 1300,
    slidesToScroll: 2,
    draggable: true,
    centerMode: false,
    variableWidth: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 760,
        settings: {
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 5000,
          cssEase: 'linear',
        },
      },
    ],
  };

  const handleMouseScroll = (e) => {
    if (e.deltaX < 0) {
      e.preventDefault();
      slickRef.slickNext();
    }
    if (e.deltaX > 0) {
      e.preventDefault();
      slickRef.slickPrev();
    }
  };

  useEffect(() => {
    if (slickContainerRef?.current && slickRef) {
      slickContainerRef.current.addEventListener('wheel', handleMouseScroll);
      return () => {
        if (slickContainerRef?.current) {
          slickContainerRef.current.removeEventListener('wheel', handleMouseScroll);
        }
      };
    }
  }, [slickContainerRef, slickRef]);

  const backgroundColor = data?.tileBG ? styles.greyBackground : '';

  return (
    <Section className={`${backgroundColor} ${styles.section}`} containerClassName={styles.container}>
      <div className={styles.grid}>
        <div className={styles.titleContainer}>
          {data?.title && <span className={styles.title}>{data.title}</span>}
          {data?.subtitle && <span className={styles.content}>{data.subtitle}</span>}
          {data?.buttonText && (
            <Link className={`button outline primary ${styles.cta}`} to={getLinkTo(data.buttonLink)}>
              {data.buttonText}
            </Link>
          )}
        </div>
        <div className={`${styles.carouselContainer}`} ref={slickContainerRef}>
          <Carousel settings={settings} sliderRef={setSlickRef}>
            {data.reviews.map((item) => {
              const { image, review, name, title, rating } = item.review;
              const starFill = (rating / 5) * 100;
              return (
                <div className={styles.card}>
                  {image && (
                    <div className={styles.imageContainer}>
                      <Image className={styles.image} image={image.asset} />
                    </div>
                  )}
                  {review && <span className={styles.quote}>{review}</span>}
                  {name && <span className={styles.name}>{name}</span>}
                  {title && <span className={styles.role}>{title}</span>}
                  <div className={styles.starRating}>
                    <div className={styles.starContainer} style={{ width: `${starFill}%` }}>
                      <Stars className={styles.stars} />
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </Section>
  );
};

export default Testimonials;

// extracted by mini-css-extract-plugin
export var section = "styles-module--section--W8HYC";
export var greyBackground = "styles-module--greyBackground--1xi-I";
export var container = "styles-module--container--3RWyW";
export var carouselContainer = "styles-module--carouselContainer--13mcx";
export var title = "styles-module--title--1iifR";
export var content = "styles-module--content--FlYr0";
export var grid = "styles-module--grid--2GXag";
export var card = "styles-module--card--U9jsx";
export var image = "styles-module--image--33E2F";
export var cardText = "styles-module--cardText--3pklF";
export var cardTitle = "styles-module--cardTitle--aggn7";
export var cardContent = "styles-module--cardContent--1xiX2";
export var author = "styles-module--author--bngYL";
export var cta = "styles-module--cta--2sB63";
export var button = "styles-module--button--2RW1F";
// extracted by mini-css-extract-plugin
export var simpleVariant = "styles-module--simpleVariant--2rFHo";
export var grid = "styles-module--grid--3jwZi";
export var visibleGrid = "styles-module--visibleGrid--3UNVL";
export var card = "styles-module--card--1nZd5";
export var simpleBoxIcon = "styles-module--simpleBoxIcon--1PEQI";
export var greyBg = "styles-module--greyBg--1ZgFc";
export var section = "styles-module--section--2jzBH";
export var textContainer = "styles-module--textContainer--3P0ND";
export var title = "styles-module--title--2h-Se";
export var content = "styles-module--content--PxfEd";
export var largeImageCard = "styles-module--largeImageCard--3jeEj";
export var icon = "styles-module--icon--2Vfsq";
export var image = "styles-module--image--3SebV";
export var value = "styles-module--value--Z7lNK";
export var property = "styles-module--property--2aqZB";
export var cta = "styles-module--cta--1n22I";
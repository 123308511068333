// extracted by mini-css-extract-plugin
export var section = "styles-module--section--1_HTm";
export var container = "styles-module--container--2WisJ";
export var authorContainer = "styles-module--authorContainer--3R6vK";
export var iconsContainer = "styles-module--iconsContainer--1R1mO";
export var iconContainer = "styles-module--iconContainer--3BhWp";
export var icon = "styles-module--icon--24mfT";
export var categoriesContainer = "styles-module--categoriesContainer--f5pLY";
export var tagsText = "styles-module--tagsText--3tCZR";
export var category = "styles-module--category--1hERt";
export var name = "styles-module--name--2d50M";
export var bio = "styles-module--bio--1WMA_";
export var image = "styles-module--image--2e06F";
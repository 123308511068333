import React, { Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Image, Section, Link, Carousel } from 'components';
import * as styles from './styles.module.scss';

const PostsGrid = ({ data }) => {
  const { tileBG, title, subtitle } = data;
  const postData = useStaticQuery(graphql`
    query postQuery {
      allSanityPost(limit: 6, sort: { fields: publishedAt, order: DESC }) {
        nodes {
          mainImage {
            image {
              asset {
                id
                url
                gatsbyImageData(layout: CONSTRAINED, width: 700, placeholder: BLURRED)
              }
            }
          }
          postAuthorRef {
            authorName
          }
          title
          subtitle
          body {
            children {
              text
            }
          }
          slug {
            current
          }
        }
      }
    }
  `);

  const {
    allSanityPost: { nodes: posts },
  } = postData;

  const backgroundColor = tileBG ? styles.greyBackground : '';

  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    cssEase: 'linear',
    arrows: false,
    speed: 2000,
    slidesToScroll: 1,
    slidesToShow: 3,
    draggable: true,
    // variableWidth: true,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Section className={`${backgroundColor} ${styles.section}`} containerClassName={styles.container}>
      <div className={styles.titleContainer}>
        <span className={styles.title}>{title}</span>
        <span className={styles.content}>{subtitle}</span>
      </div>
      {/* post grid */}
      <>
        {posts.length > 3 ? (
          <div className={styles.carouselContainer}>
            <Carousel settings={settings}>
              {posts.map((item) => (
                <Card post={item} />
              ))}
            </Carousel>
          </div>
        ) : (
          <div className={styles.grid}>
            {posts.map((item) => (
              <Card post={item} />
            ))}
          </div>
        )}
      </>
      <Link to="/news" className={`button outline primary ${styles.button}`}>
        View all Articles
      </Link>
    </Section>
  );
};

const Card = ({ post }) => {
  const {
    title: cardTitle,
    subtitle: cardContent,
    mainImage,
    postAuthorRef,
    slug,
    body,
  } = post;
  const { authorName } = postAuthorRef || {};
  // extract post body
  const bodyText = [];
  body.forEach((section, i) => {
    const { children } = section;
    bodyText.push(children?.[0]?.text);
  });
  const fullBody = bodyText.join('');
  // mins to read
  const wordsPerMinute = 200;
  const textLength = fullBody?.split(' ').length;
  const minsToRead = Math.ceil(textLength / wordsPerMinute);

  return (
    <div className={styles.card} key={cardTitle}>
      <Image className={styles.image} image={mainImage?.image?.asset} />
      <div className={styles.cardText}>
        <span className={styles.cardTitle}>{cardTitle}</span>
        <p className={styles.cardContent}>{cardContent}</p>
        <div className={styles.authorContainer}>
          <div className={styles.authorText}>
            {authorName && (
              <span className={styles.author}>
                By {authorName} | {minsToRead} min read
              </span>
            )}
          </div>
        </div>
      </div>
      <Link className={styles.cta} to={`/news/${slug?.current}`}>
        Read Article
      </Link>
    </div>
  );
};

export default PostsGrid;

import React, { Fragment } from 'react';
import { Image, Section, Link } from 'components';
import Twitter from 'images/twitter.inline.svg';
import Facebook from 'images/facebook.inline.svg';
import LinkedIn from 'images/linkedin.inline.svg';
import * as styles from './styles.module.scss';

const PostHeader = ({ data }) => {
  const { author, publishedAt, slug, body } = data;
  const { name, photo } = author;

  // mins to read
  const wordsPerMinute = 200; // average reading time
  const textLength = body?.length; // Split by words
  const minsToRead = Math.ceil(textLength / wordsPerMinute);

  // social sharing
  const rootUrl = 'https://www.mkpgroup.com.au';
  const socialLinks = [
    {
      comp: <LinkedIn className={styles.icon} />,
      link: `https://www.linkedin.com/shareArticle?mini=true&url=${rootUrl}${slug}`,
    },
    {
      comp: <Facebook className={styles.icon} />,
      link: `https://www.facebook.com/sharer/sharer.php?u=${rootUrl}${slug}`,
    },
    {
      comp: <Twitter className={styles.icon} />,
      link: `https://twitter.com/intent/tweet?url=${rootUrl}${slug}`,
    },
  ];

  return (
    <Section className={styles.section} containerClassName={styles.container}>
      <div className={styles.authorContainer}>
        {photo && <Image className={styles.image} image={ photo.asset } />}
        <div className={styles.authorText}>
          {name && <span className={styles.name}>{name}</span>}
          {/* // date will need formatting when real data comes in */}
          {publishedAt && (
            <div className={styles.date}>
              <span className={styles.time}>{publishedAt}</span> · <span>{minsToRead} min read</span>
            </div>
          )}
        </div>
      </div>
      <div className={styles.shareContainer}>
        <span className={styles.shareText}>Share</span>
        <div className={styles.iconsContainer}>
          {socialLinks.map((item, index) => {
            const { comp, link } = item;
            return (
              <Fragment key={link}>
                {link && (
                  <Link to={link} className={styles.iconContainer}>
                    {comp}
                  </Link>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
    </Section>
  );
};

export default PostHeader;

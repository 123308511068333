import React from 'react';
import { Section, Image, OnVisible, Link } from 'components';
import * as Icons from 'react-icons/fa';
import * as styles from './styles.module.scss';

const Boxes = ({ data }) => {
  // refine logic based on live data
  const simpleVariant = data.boxType === 'simple-box';
  const DynamicFontAwesomeIcon = (iconObj) => {
    if (!iconObj?.name) {
      return null;
    }
    return Icons[iconObj.name];
  };

  const isGroupFour = data?.boxes?.length % 4 === 0 ? styles.isGroupFour : '';

  return (
    <Section
      className={`${simpleVariant ? styles.simpleVariant : ''} ${isGroupFour} ${data?.tileBG ? styles.greyBg : ''} ${
        styles.section
      }`}
      containerClassName={styles.container}
      sliceName="Boxes"
    >
      {(data?.title || data?.subtitle) && (
        <div className={styles.textContainer}>
          {data?.title && <span className={styles.title}>{data.title}</span>}
          {data?.subtitle && <span className={styles.content}>{data.subtitle}</span>}
        </div>
      )}
      <OnVisible className={styles.grid} visibleClassName={styles.visibleGrid}>
        {data.boxes.map((item, index) => {
          const { icon, heading, subheading, _type: boxType, image, imageSize } = item;

          const Icon = DynamicFontAwesomeIcon(icon);
          return (
            <>
              {boxType === 'simpleBox' ? (
                <Image image={icon?.asset} className={styles.simpleBoxIcon} />
              ) : (
                <div
                  className={`${imageSize === 'large-box' ? styles.largeImageCard : ''} ${styles.card}`}
                  style={{ transitionDelay: `${index * 0.2}s` }}
                >
                  {Icon ? <Icon className={styles.icon} /> : <Image image={image?.asset} className={styles.icon} />}
                  {heading && <span className={styles.value}>{heading}</span>}
                  {subheading && <span className={styles.property}>{subheading}</span>}
                </div>
              )}
            </>
          );
        })}
      </OnVisible>
      {data?.link?.slug?.current && (
        <Link to={data?.link?.slug?.current} className={`button ${styles.cta}`}>
          {data?.linkText}
        </Link>
      )}
    </Section>
  );
};

export default Boxes;

import React, { useState } from 'react';
import { Section } from 'components';
import Chevron from 'images/icons/right-chevron.inline.svg';
import * as styles from './styles.module.scss';

const Faq = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (event, index) => {
    event.preventDefault();
    setActiveIndex(index);
  };

  const schema = `
  <script type="application/ld+json">
    {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      ${data.faqElements.map(
    (item) => `
        {
          "@type": "Question",
          "name": "${item.question}",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "${item.answer}"
          }
        }`
      )}]
    }
    </script>
  `;

  return (
    <Section className={styles.section} containerClassName={styles.container}>
      <div className={styles.textContainer}>
        {data.title && <span className={styles.title}>{data.title}</span>}
        {data.subtitle && <span className={styles.content}>{data.subtitle}</span>}
      </div>
      <div className={styles.faqContainer}>
        <div className={styles.questionList}>
          {data.faqElements.map((item, index) => {
            const { question } = item;
            return (
              <div>
                <div
                  className={`${activeIndex === index ? styles.activeItem : ''} ${styles.faqItem}`}
                  onClick={(event) => handleClick(event, index)}
                >
                  <span className={styles.questionText}>{question}</span>
                  <Chevron className={styles.chevron} />
                </div>
                {activeIndex === index && (
                  <div className={styles.mobileAnswer}>
                    <span className={styles.answer}>{data.faqElements[activeIndex]?.answer}</span>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className={styles.answerBox}>
          <span className={styles.currentQuestion}>{data.faqElements[activeIndex]?.question}</span>
          <p className={styles.answer}>{data.faqElements[activeIndex]?.answer}</p>
        </div>
      </div>
      <div dangerouslySetInnerHTML={{ __html: schema }} />
    </Section>
  );
};

export default Faq;

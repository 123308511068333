import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import { FormField } from 'components';
import * as styles from './styles.module.scss';

const Form = ({ fields, onSubmit, formSparkId, submitButton, className, department }) => {
  const { register, handleSubmit, formState } = useForm({ mode: 'onSubmit' });
  const { errors, isSubmitting } = formState;
  const [submissionError, setSubmissionError] = useState(false);
  const [success, setSuccess] = useState(false);

  const defaultOnSubmit = async (values) => {
    const whereToSend = {
      'Finance Enquiry Contact Form': 'YlIXW5te',
      'Property Finance Enquiry': 'pLomxfCf',
      'Insurance Enquiry Contact Form': 'rU7ZDS6w',
      'Contact Us (General)': '1YZ87pa5',
      'Corporate Advisory Enquiry Form': 'flxgnzsb',
      'Leave Feedback for our Team': 'OtDI4zE6',
      'Insurance Claim': 'Gm7KSKIl',
    };

    const departmentValue = whereToSend[department];
    setSuccess(true);

    try {
      const url = `https://submit-form.com/${departmentValue || formSparkId || ''}`; // pass formSparkId to send to formSpark
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(values),
      };
      const response = await fetch(url, config);
      if (response.ok) {
        setSuccess(true);
      }
    } catch (error) {
      console.error('Error submitting form', error);
      setSubmissionError('Oops something went wrong, please try again');
    }
  };

  return (
    <section>
      {submissionError && <p>{submissionError}</p>}
      <form onSubmit={handleSubmit(defaultOnSubmit)} className={`${styles.form} ${className || ''}`}>
        <button type="submit" disabled aria-hidden="true" style={{ display: 'none' }} />
        {fields.map((field) => {
          const hasError = errors[field.name];
          return (
            <div key={field.label} className={`${styles.field} ${field.className || ''}`}>
              <span className={styles.label}>{field.label}</span>
              <FormField {...field} register={register} />
              <input
                {...register('_gotcha')}
                type="checkbox"
                name="_gotcha"
                style={{ display: 'none' }}
                tabIndex="-1"
                autoComplete="off"
              />
              <div className={`${styles.fieldError} ${hasError ? styles.activeError : ''}`}>
                {hasError && <span>{field.validationMessage || 'This field is required'}</span>}
              </div>
            </div>
          );
        })}
        {!success && (
          <button type="submit" className={`button ${styles.formButton}`} disabled={isSubmitting}>
            {isSubmitting ? submitButton?.submissionText || 'Submitting' : submitButton?.defaultText || 'Submit'}
          </button>
        )}
        {success && <span className={styles.successMessage}>You've successfully sent your enquiry!</span>}
      </form>
    </section>
  );
};

export default Form;

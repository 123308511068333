import React from 'react';
import { Link } from 'components';
import House from 'images/icons/house.inline.svg';
import './styles.scss';

const Breadcrumbs = ({ noHomeLink, parentUid, parentName, currentPageUid, currentPageName, centreAlign, isBlog }) => {
  const generatePath = (slug) => {
    if (slug === 'home') return '/';
    const formmatedSlug = slug?.replace(/\./g, '/');
    return `/${formmatedSlug}/`;
  };

  const breadcrumbs = [];

  if (!noHomeLink) {
    breadcrumbs.push({
      uid: '/',
      name: '',
      icon: <House className="breadcrumb-home-icon" />,
    });
  }

  if (isBlog) {
    breadcrumbs.splice(1, 0, {
      uid: '/news',
      name: 'News',
    });
  }

  if (parentUid) {
    breadcrumbs.push({
      uid: generatePath(parentUid),
      name: parentName,
    });
  }

  breadcrumbs.push({
    uid: currentPageUid,
    name: currentPageName,
    isCurrent: true,
  });

  const schema = `
    <script type="application/ld+json">
      {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement":[
          ${breadcrumbs.map(
            (breadcrumb, index) => `{
              "@type": "ListItem",
              "position": ${index + 1},
              "item": {
                "@id": "https://www.mkpgroup.com.au${breadcrumb.uid}",
                "item": "https://www.mkpgroup.com.au${breadcrumb.uid}",
                "name": "${breadcrumb.name}"
              }
            }`
          )}
        ]
      }
    </script>`;

  return (
    <section className={`breadcrumbs ${centreAlign ? 'centre-align' : ''}`}>
      <div className="wrapper">
        {breadcrumbs.map((breadcrumb) => {
          if (breadcrumb.isCurrent) {
            const crumb = breadcrumb.name;
            return (
              <div className="breadcrumbs-item last-item" key={breadcrumb.name}>
                {crumb}
              </div>
            );
          }
          return (
            <div className="breadcrumbs-item" key={breadcrumb.name}>
              <Link to={breadcrumb.uid}>
                {breadcrumb.name}
                {breadcrumb?.icon}
              </Link>
            </div>
          );
        })}
        <div dangerouslySetInnerHTML={{ __html: schema }} />
      </div>
    </section>
  );
};

export default Breadcrumbs;

import React from 'react';
import { Link } from 'components';
import { siteName } from '../../../../config/website';
import * as styles from './styles.module.scss';

const Logo = ({ mobileVersion, className }) => (
  <Link className={`${mobileVersion ? styles.mobileVersion : ''} ${className || ''} ${styles.logo}`} to="/">
    {siteName}
  </Link>
);

export default Logo;

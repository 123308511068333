import React, { useEffect, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import axios from 'axios';
import { Section } from 'components';
import * as styles from './styles.module.scss';

const SalesforceForm = ({ data }) => {
  const formHtml = data?.formHtml;
  const title = data?.title;

  useEffect(() => {
    // find container
    const formArray = document.getElementsByClassName('sales-force-form');
    const outerEl = formArray[0];
    // insert form into DOM
    outerEl.innerHtml = formHtml;
    // target form
    const form = outerEl.lastChild;
    form.onsubmit = (e) => onSubmit(e, form);
  }, []);

  // structure form values
  // on submit
  const onSubmit = async (event, form) => {
    event.preventDefault();
    const url = `${process.env.GATSBY_SITE_URL}/api/salesforce-forms`;
    const values = {};
    for (const item of new FormData(form)) {
      const name = item[0];
      const value = item[1];
      values[name] = value;
    }
    const body = {
      values,
      url: form.action,
    };
    // send to gatsby severless func @ /api/salesforce-forms
    try {
      const response = await axios.post(url, body);
      if (response.ok) {
        console.log('success');
      }
    } catch (error) {
      console.log('nope', error);
    }
  };

  return (
    <Section className={styles.section}>
      <div className={styles.innerContainer}>
        <StaticImage alt="handshake" className={styles.image} src="../../images/placeholders/hands.png" />
        <div className={styles.form}>
          {title && <span className={styles.title}>{title}</span>}
          <div dangerouslySetInnerHTML={{ __html: formHtml }} className="sales-force-form" />
        </div>
      </div>
    </Section>
  );
};
export default SalesforceForm;

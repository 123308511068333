import React, { useState } from 'react';
import { Logo, Section, Link } from 'components';
import Facebook from 'images/facebook.inline.svg';
import Linkedin from 'images/linkedin.inline.svg';
import { useStaticQuery, graphql } from 'gatsby';
import * as styles from './styles.module.scss';

const Footer = () => {
  const footerData = useStaticQuery(graphql`
    query footerQuery {
      sanityNavigation {
        footerNav {
          navItem {
            _key
            _type
            navHeader
            footerNav {
              ... on SanitySingletonNavItem {
                _key
                _type
                title
                linkTo {
                  ... on SanityPage {
                    id
                    slug {
                      current
                    }
                  }
                }
              }
              ... on SanityExternalNavItem {
                _key
                _type
                to
                title
              }
            }
          }
        }
        privacyPage {
          slug {
            current
          }
        }
        termsPage {
          slug {
            current
          }
        }
        linkedInUrl
        facebookUrl
      }
    }
  `);
  const footerBottom = footerData?.sanityNavigation;
  const { privacyPage, termsPage, linkedInUrl, facebookUrl } = footerBottom;
  const footerNav = footerData?.sanityNavigation?.footerNav?.navItem;
  const [showSublinks, setShowSublinks] = useState(false);

  const handleClick = (event, index) => {
    event.preventDefault();
    if (showSublinks === index) {
      setShowSublinks(null);
    } else {
      setShowSublinks(index);
    }
  };

  return (
    <footer className={styles.footer}>
      <Section className={styles.section} containerClassName={styles.container}>
        <div className={styles.footerMain}>
          {/* LEFT COL */}
          <div className={styles.leftCol}>
            <Logo fullLogoMobile />
            <Link to="tel:1300000657" className={styles.phone}>
              1300 000 657
            </Link>
            <div className={styles.socials}>
              {/* // need links for socials */}
              {linkedInUrl && (
                <Link to={linkedInUrl}>
                  <Linkedin className={styles.socialIcon} />
                </Link>
              )}
              {facebookUrl && (
                <Link to={facebookUrl}>
                  <Facebook className={styles.socialIcon} />
                </Link>
              )}
            </div>
          </div>
          {/* NAV LINKS */}
          <div className={styles.navLinks}>
            {footerNav.map((link, index) => {
              const { navHeader: text, footerNav: sublinks } = link;
              return (
                <div className={styles.navItem} onClick={(event) => handleClick(event, index)}>
                  <div
                    className={`${showSublinks === index ? styles.activeParent : styles.inactiveParent} ${
                      styles.parentContainer
                    }`}
                  >
                    <div key={text} className={styles.parentText} activeClassName={styles.activeLink}>
                      <Link key={text} className={styles.parentText}>
                        {text}
                      </Link>
                      <div className={styles.accordionItemToggleContainer}>
                        <div className={styles.accordionItemToggle} />
                      </div>
                    </div>
                    {sublinks && (
                      <div className={`${showSublinks === index ? styles.mobileSublinks : ''} ${styles.sublinks}`}>
                        {sublinks.map((sublink) => (
                          <Link
                            className={styles.sublink}
                            to={`/${sublink?.linkTo?.slug?.current}`}
                            activeClassName={styles.activeLink}
                          >
                            {sublink.title}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* LEGAL */}
        <div className={styles.sitemap}>
          <div className={styles.legal}>
            <span>Moody Kiddell & Partners Unit Trust ABN 28 187 241 830 (Australian Credit Licence 388771)</span>
            <span>Moody Kiddell & Partners (Insurance) Pty Ltd ABN 48 071 572 828 (AFSL 512 898)</span>
          </div>
          <div className={styles.copyright}>
            <span>© Copyright {new Date().getFullYear()} Moody Kiddell & Partners</span>
            {/* <div className={styles.policies}>
              <Link to={termsPage?.slug?.current ? `/${termsPage?.slug?.current}` : '/'}>Terms & Conditions</Link> |{' '}
              <Link to={privacyPage?.slug?.current ? `/${privacyPage?.slug?.current}` : '/'}>Privacy Policy </Link>
            </div> */}
          </div>
        </div>
      </Section>
    </footer>
  );
};

export default Footer;

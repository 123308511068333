import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { PostsGrid } from 'components';
import * as styles from './styles.module.scss';

const Posts = ({ data }) => {
  const postData = useStaticQuery(graphql`
    query postsQuery {
      allSanityPost {
        nodes {
          mainImage {
            image {
              asset {
                url
                gatsbyImageData(layout: CONSTRAINED, width: 1000, placeholder: BLURRED)
              }
            }
          }
          postAuthorRef {
            authorName
          }
          title
          subtitle
          body {
            children {
              text
            }
          }
          slug {
            current
          }
        }
      }
    }
  `);

  const { allSanityPost } = postData;

  return <PostsGrid data={data} />;
};

export default Posts;

import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Section, Form } from 'components';
import * as styles from './styles.module.scss';

const ContactForm = ({ data }) => {
  const { title, contactOptions } = data;
  const isFeedback = contactOptions === 'Leave Feedback for our Team';
  const isClaim = contactOptions === 'Insurance Claim';
  const fields = [
    {
      type: 'text',
      name: 'firstName',
      placeholder: 'First name',
      label: 'First name*',
      className: styles.splitLeft,
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter your first name',
    },
    {
      type: 'text',
      name: 'lastName',
      placeholder: 'Last name',
      label: 'Last name*',
      className: styles.splitRight,
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter your last name',
    },
    {
      type: 'email',
      name: 'email',
      placeholder: 'example@mail.com',
      label: 'Email*',
      defaultValue: '',
      className: isFeedback || isClaim ? styles.splitLeft : '',
      validation: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
      validationMessage: 'Please enter a valid email',
    },
    {
      type: 'tel',
      name: 'phone',
      placeholder: 'E.g 0414 000 000',
      label: 'Contact number*',
      defaultValue: '',
      className: isFeedback || isClaim ? styles.splitRight : styles.splitLeft,
      validation: { required: true, minLength: 8 },
      validationMessage: 'Please enter a valid number',
    },
    {
      type: 'select',
      options: ['NSW', 'VIC', 'QLD', 'WA', 'NT', 'TAS', 'SA', 'ACT'],
      name: 'state',
      placeholder: 'Select State',
      className: styles.splitRight,
      label: 'State*',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please select a state',
    },
    {
      type: 'textarea',
      name: 'message',
      placeholder: 'Type your message...',
      label: 'How can we help you?',
      defaultValue: '',
    },
  ];

  if (isFeedback) {
    fields.splice(fields.indexOf(fields.find((item) => item.name === 'state')), 1);
  }

  if (isClaim) {
    fields.splice(fields.indexOf(fields.find((item) => item.label === 'How can we help you?')), 1);
    fields.splice(fields.indexOf(fields.find((item) => item.name === 'state')), 1);
    const topFields = [
      {
        type: 'text',
        name: 'ABN',
        placeholder: 'ABN',
        label: 'ABN*',
        className: styles.splitLeft,
        defaultValue: '',
        validation: { required: true },
        validationMessage: 'Please enter your ABN',
      },
      {
        type: 'text',
        name: 'policyNumber',
        placeholder: 'Policy number',
        label: 'Policy Number*',
        defaultValue: '',
        validation: { required: true },
        validationMessage: 'Please enter your policy number',
        className: styles.splitRight,
      },
    ];
    const bottomFields = [
      {
        type: 'select',
        name: 'insuranceClass',
        placeholder: 'Select Class of Insurance *',
        label: 'Select Class of Insurance *',
        defaultValue: '',
        validation: { required: true },
        validationMessage: 'Please select your claim class',
        options: [
          'Asbestos Liability',
          'Business Package',
          'Caravan Insurance',
          'Carriers Cargo',
          'Contract Works',
          'Corporate Travel',
          'Cyber',
          'Directors & Officers',
          'Electronic Equipment Breakdown',
          'Farm Package',
          'General Property',
          'Home & Contents',
          'Inland Transit',
          'Industrial Special Risks',
          'IT Liability',
          'Journey Cover',
          'Landlords',
          'Machinery Breakdown',
          'Management Liability',
          'Marine Cargo',
          'Medical Malpractice',
          'Motor Vehicle',
          'Office Package',
          'Personal Accident & Sickness',
          'Plant & Equipment',
          'Pleasure Craft',
          'Products Liability',
          'Professional Indemnity',
          'Public Liability',
          'Strata',
          'Tradesman’s Tools',
          'Asbestos Liability',
        ],
      },
      {
        type: 'textarea',
        name: 'claimDetails',
        label: 'Claim details*',
        placeholder: 'Your claim details here',
        validation: { required: true },
        validationMessage: 'Please enter your claim details',
      },
    ];
    fields.unshift(...topFields);
    fields.push(...bottomFields);
  }

  return (
    <Section className={styles.section} containerClassName={styles.container}>
      <div className={styles.innerContainer}>
        <StaticImage alt="handshake" className={styles.image} src="../../images/placeholders/hands.png" />
        <div className={styles.formContainer}>
          <span className={styles.title}>{title}</span>
          <Form fields={fields} department={contactOptions} />
        </div>
      </div>
    </Section>
  );
};

export default ContactForm;

import React, { useState } from 'react';
import * as Icons from 'react-icons/fa';
import { getLinkTo } from '../../helpers/linkResolver';
import { Link, Section, Breadcrumbs, OnVisible } from '../../components';
import * as styles from './styles.module.scss';

const DynamicFontAwesomeIcon = ({ name }) => Icons[name];

const Hero = ({ data, slug }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  // will need to write logic to say if homepage, render tall version, else, skinny
  const isHomePage = data?.homePage;
  const parentTitle = data?.parentPage?.title;
  const parentSlug = data?.parentPage?.slug?.current;
  const tallVersion = isHomePage ? styles.tallVersion : '';
  const hasServices = data?.services.length > 0 ? styles.hasServices : '';
  const extraSpace = !data?.buttonText && !data?.subtitle ? styles.extraSpace : '';

  const handleClick = (event, index) => {
    event.preventDefault();
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const activeSubservices = data?.services[activeIndex]?.services;
  return (
    <>
      <Section
        className={`${hasServices} ${extraSpace} ${styles.section} ${tallVersion}`}
        bgImage={data?.backgroundImage?.asset}
        containerClassName={styles.container}
        bgRight={data?.solid}
        sliceName="Hero"
      >
        {!isHomePage && (
          <Breadcrumbs
            parentUid={parentSlug || ''}
            parentName={parentTitle || ''}
            currentPageUid={slug}
            currentPageName={data?.title}
          />
        )}
        <div className={styles.textContainer}>
          {data?.title && <h1 className={styles.title}>{data?.title}</h1>}
          {data?.subtitle && <span className={styles.content}>{data?.subtitle}</span>}
          {data?.buttonText && (
            <Link
              className={
                isHomePage ? `button secondary ${styles.cta} ${styles.homepageCta}` : `button secondary ${styles.cta}`
              }
              to={getLinkTo(data?.buttonLink)}
            >
              {data?.buttonText}
            </Link>
          )}
        </div>
        {data?.services?.length > 0 && (
          <OnVisible className={styles.services} visibleClassName={styles.visibleServices}>
            {data.services.map((item, index) => {
              const { icon, title, subtitle } = item;
              const Icon = DynamicFontAwesomeIcon(icon);
              return (
                <div
                  onClick={(e) => {
                    handleClick(e, index);
                  }}
                  role="button"
                  tabIndex={0}
                  className={`${styles.serviceCard} ${index === activeIndex ? styles.active : ''}`}
                  key={`${title} ${subtitle}`}
                  style={{ transitionDelay: `${index * 0.2}s` }}
                >
                  <Icon className={styles.icon} />
                  <h2 className={styles.cardTitle}>{title}</h2>
                  <span className={styles.cardSubtitle}>{subtitle}</span>
                </div>
              );
            })}
          </OnVisible>
        )}
      </Section>
      {activeSubservices?.length > 0 && (
        <Section className={styles.subServices} containerClassName={styles.subserviceContainer}>
          {activeSubservices.map((service) => {
            const { title: serviceTitle, icon: serviceIcon, link } = service;
            const Icon = DynamicFontAwesomeIcon(serviceIcon);
            return (
              <Link to={getLinkTo(link)} className={styles.card} key={serviceTitle}>
                <div className={styles.iconContainer}>
                  <Icon className={styles.serviceIcon} />
                </div>
                <span className={styles.cardText}>{serviceTitle}</span>
              </Link>
            );
          })}
        </Section>
      )}
    </>
  );
};
export default Hero;

import React from 'react';

const PageComponent = ({ data }) => {
  // const { primary } = data;
  // const { title: titleData, subtitle: subtitleData } = primary;
  // const title = titleData?.text;
  // const subtitle = subtitleData?.html;

  return (
    <>
      {/* <div>{title}</div>
      {subtitle} */}
      <div>Hello World</div>
    </>
  );
};

export default PageComponent;

import React, { Fragment } from 'react';
import { Image, Section, Link } from 'components';
import Twitter from 'images/twitter.inline.svg';
import Facebook from 'images/facebook.inline.svg';
import LinkedIn from 'images/linkedin.inline.svg';
import * as styles from './styles.module.scss';

const PostFooter = ({ data }) => {
  const { author, slug, categories } = data;
  const { name, photo, bio } = author;

  // social sharing
  const rootUrl = 'https://www.mkpgroup.com.au';
  const socialLinks = [
    {
      comp: <LinkedIn className={styles.icon} />,
      text: 'LinkedIn',
      link: `https://www.linkedin.com/shareArticle?mini=true&url=${rootUrl}${slug}`,
    },
    {
      comp: <Facebook className={styles.icon} />,
      text: 'Facebook',
      link: `https://www.facebook.com/sharer/sharer.php?u=${rootUrl}${slug}`,
    },
    {
      comp: <Twitter className={styles.icon} />,
      text: 'Twitter',
      link: `https://twitter.com/intent/tweet?url=${rootUrl}${slug}`,
    },
  ];

  return (
    <Section className={styles.section} containerClassName={styles.container}>
      <div className={styles.iconsContainer}>
        {socialLinks.map((item, index) => {
          const { comp, link, text } = item;
          return (
            <Fragment key={link}>
              {link && (
                <Link to={link} className={styles.iconContainer}>
                  {comp}
                  <span className={styles.linkText}>Share on {text}</span>
                </Link>
              )}
            </Fragment>
          );
        })}
      </div>
      {categories.length > 0 && (
        <div className={styles.categoriesContainer}>
          <span className={styles.tagsText}>Tags:</span>
          {categories.map((item, index) => {
            const title = item?.title;
            const link = item?.slug?.current || ""
            return (
              <Fragment key={link}>
                <Link to={`/news/${link}`} className={styles.category}>
                  {title}
                  {index !== categories.length - 1 && ','}
                </Link>
              </Fragment>
            );
          })}
        </div>
      )}
      <div className={styles.authorContainer}>
        {photo && <Image className={styles.image} image={ photo.asset } />}
        <div className={styles.authorText}>
          {name && <span className={styles.name}>{name}</span>}
          {bio && <span className={styles.bio}>{bio}</span>}
          {/* // date will need formatting when real data comes in */}
        </div>
      </div>
    </Section>
  );
};

export default PostFooter;

import React from 'react';
import { Section, Breadcrumbs } from 'components';
import * as styles from './styles.module.scss';

const BlogHeader = ({ title, subtitle, parentTitle, parentSlug, currentPageUid, currentPageName, image }) => (
  <Section
    className={`${styles.extraSpace} ${styles.section}`}
    bgImage={image?.asset}
    containerClassName={styles.container}
    bgRight
  >
    <Breadcrumbs
      parentUid={parentSlug || ''}
      parentName={parentTitle || ''}
      currentPageUid={currentPageUid}
      currentPageName={currentPageName}
      isBlog
    />
    <div className={styles.textContainer}>
      {title && <h1 className={styles.title}>{title}</h1>}
      {subtitle && <p className={styles.content}>{subtitle}</p>}
    </div>
  </Section>
);
export default BlogHeader;

import React from 'react';
import { Section, Image, Link } from '../../components';
import * as styles from './styles.module.scss';

const TitleAndLogo = ({ data }) => (
  <Section className={styles.section} containerClassName={styles.container} sliceName="TitleAndLogo">
    <Link to={data?.titleLink?.slug?.current} className={styles.title}>
      {data.title}
    </Link>
    <Link to={data?.imageLink?.slug?.current} className={styles.imgContainer}>
      <Image className={styles.image} image={data.image.asset} />
    </Link>
  </Section>
);

export default TitleAndLogo;

import React from 'react';
import { Image, Section, Breadcrumbs } from 'components';
import * as styles from './styles.module.scss';

const PostHeader = ({ data, location, categories }) => {
  const { title, subtitle, slug, mainImage } = data;
  const mainParent = categories[0];

  return (
    <Section className={styles.section} containerClassName={styles.container}>
      <Breadcrumbs
        parentUid={`news/${mainParent?.slug?.current}`}
        parentName={mainParent?.title}
        currentPageUid={slug?.current}
        currentPageName={title}
        isBlog
      />
      <div className={styles.textContainer}>
        <span className={styles.title}>{title}</span>
        <span className={styles.subtitle}>{subtitle}</span>
      </div>
      <Image className={styles.image} image={mainImage?.image?.asset} alt={mainImage?.altText} />
    </Section>
  );
};

export default PostHeader;

/* eslint-disable react/display-name */
import React from 'react';
import { Link, Image } from 'components';
import * as styles from './styles.module.scss';

const BlockContent = require('@sanity/block-content-to-react');

const BlockContents = ({ data, className, publishedAt, location }) => {
  const blocks = data;
  const newBlocks = blocks.map((i) => {
    i.markDefs = i.markDefs || [];
    return i;
  });

  const hasContainer = newBlocks.length > 1;

  const serializers = {
    types: {
      block: ({ node: { style }, children }) => {
        if (style === `h1`)
          return (
            <div className={styles.paragraphContainer}>
              <h1 className={hasContainer ? '' : className}>{children}</h1>
            </div>
          );

        if (style === `h2`)
          return (
            <div className={styles.paragraphContainer}>
              <h2 className={hasContainer ? '' : className}>{children}</h2>
            </div>
          );

        if (style === `h3`)
          return (
            <div className={styles.paragraphContainer}>
              <h3 className={hasContainer ? '' : className}>{children}</h3>
            </div>
          );

        if (style === `h4`)
          return (
            <div className={styles.paragraphContainer}>
              {' '}
              <h4 className={hasContainer ? '' : className}>{children}</h4>
            </div>
          );

        if (style === `h5`)
          return (
            <div className={styles.paragraphContainer}>
              <h5 className={hasContainer ? '' : className}>{children}</h5>
            </div>
          );

        if (style === 'centered') return <div className={styles.centered}>{children}</div>;

        if (children)
          return (
            <div className={styles.paragraphContainer}>
              <p className={styles.paragraph}>{children}</p>
            </div>
          );
      },
      blockContentImage: (props) => {
        const { node } = props;
        const overflowMobile = node?.overflowMobile || false;
        const altText = node?.asset?.altText;
        const caption = node?.caption;
        const { markDefs, ...imageProps } = node;
        const imageAsset = props?.node?.image?.asset;
        return (
          <div className={`${overflowMobile ? styles.overflowImage : ''} ${styles.imageWrapper}`}>
            <Image className={styles.image} image={imageAsset} width={1600} alt={node?.alt || altText || ''} />
            <span className={styles.imageCaption}>{caption}</span>
          </div>
        );
      },
    },
    list: ({ children, type }) => {
      const bullet = type === 'bullet';
      const ticked = type === 'ticked';
      const crossed = type === 'crossed';
      const number = type === 'number';

      if (bullet) {
        return <ul className={`${styles.bullet} ${hasContainer ? '' : className} `}>{children}</ul>;
      }
      if (number) {
        return <ol className={`${hasContainer ? '' : className} ${styles.ordered}`}>{children}</ol>;
      }

      if (ticked) {
        return <ul className="ticked">{children}</ul>;
      }

      if (crossed) {
        return <ul className="crossed">{children}</ul>;
      }

      return <ol className={styles.ordered}>{children}</ol>;
    },
    listItem: ({ children }) => <li>{children}</li>,
    marks: {
      link: ({ children, mark }) =>
      
        mark.href ? (
          <Link to={mark?.href} rel={mark?.linkRel} className={styles.link}>
            {children}
          </Link>
        ) : null,
      internalLink: ({ children, mark }) => {
        const fileDownload = mark?.internal?.pdfFile?.asset.url
        
        return(
        <Link to={fileDownload? fileDownload:`/${mark?.internal?.slug?.current}/`} className={styles.link}>
          {children}
        </Link>
      )},
      strong: ({ children }) => <strong className={styles.strong}>{children}</strong>,
      em: ({ children }) => <em className={styles.em}>{children}</em>,
    },
  };

  if (blocks) {
    return (
      <BlockContent
        blocks={newBlocks}
        serializers={serializers}
        projectId={process.env.GATSBY_SANITY_PROJECT_ID}
        dataset={process.env.GATSBY_SANITY_DATASET}
        className={`block-content ${className}`}
      />
    );
  }
  return null;
};

export default BlockContents;
